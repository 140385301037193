.custom-checkbox input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #ccc;
    border-radius: 3px;
    position: relative;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"]:checked {
    background-color: #1A8044;
    border-color: #1A8044;
}

.custom-checkbox input[type="checkbox"]:checked::after {
    content: '\2713';
    color: white;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}