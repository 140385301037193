.timeline-container {
    position: relative;
}

.timeline-line {
    position: absolute;
    left: 22px;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #1A8044;
}

.timeline-item {
    display: flex;
    align-items: flex-start;
    padding: 10px;
    position: relative;
}

.tick-mark {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.9rem;
}

.tick-circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #1A8044;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tick-icon {
    font-size: 0.8rem;
    color: white;
}

.timeline-content {
    flex: 1;
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid #ccc;
}